import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout'
import BrandCTA from '../../../components/brand-cta'
import polaroid from '../../../images/5-good-reasons-polaroid.png'

const metaTags = {
  description: '5 good reasons to talk to your doctor about SUPPRELIN® LA (histrelin acetate).',
  keywords: 'Homepage',
  title: '5 Good Reasons to Ask for SUPPRELIN® LA (histrelin acetate)'
}

const IndexPage = () => (
  <Layout meta={metaTags}>
    <Row>
      <Col xs={12}>
        <h1>Five good reasons to ask your doctor about SUPPRELIN<sup>&reg;</sup> LA</h1>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6}>
        <h2>Discover a treatment that may fit your child’s life</h2>
        <p>When discussing a treatment option for your child with CPP, be sure to ask your doctor about SUPPRELIN<sup>&reg;</sup> LA:</p>
        <ol>
          <li>
            <strong>FDA approved</strong>, with proven hormone (LH) <strong>suppression in 100% of treated children</strong>, and 97% to 100% sex hormone suppression*
          </li>
          <li>
            The <strong>longest-acting</strong> single-dose treatment for CPP<sup>&dagger;</sup>
          </li>
          <li>
            Delivers <strong>continuous</strong>, <strong>each-and-every day</strong> CPP treatment &mdash; <strong>for 12 full months</strong>
          </li>
          <li>
            <strong>No deep muscular injection</strong> required
          </li>
          <li>
            <strong>Selected by doctors and parents for over 16 years, with thousands of implants used to treat patients each year in the US</strong>
          </li>
        </ol>
        <p className='footnote no-mb'>LH=Luteinizing hormone.</p>
        <p className='footnote asterik no-mb'>In a clinical study on 36 patients (33 girls and 3 boys), LH was suppressed in 100% of the patients after the first month of treatment with SUPPRELIN<sup>&reg;</sup> LA.</p>
        <p className='footnote dagger'>Keep all scheduled visits to the doctor. The doctor will do regular exams and blood tests to check for signs of puberty. Your child’s healthcare professional may recommend that he or she avoid heavy play or exercise for about a week after insertion of SUPPRELIN<sup>&reg;</sup> LA. The doctor will decide when to end treatment so that puberty can begin as normal.</p>
      </Col>
      <Col xs={12} md={6}>
        <img src={polaroid} alt="Polaroid 4" title="Polaroid 4" />
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          href={'/patients/caregiver-signup'}
          LinkCaption="Order an all-in-one Caregiver Kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP"
        >
          Order an all-in-one Caregiver Kit for information about SUPPRELIN<sup>&reg;</sup> LA and CPP
        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          href="/patients/caregiver-resources/#video=3"
          LinkCaption="Watch a mother and daughter share their experience with SUPPRELIN<sup>&reg;</sup> LA"
        >
          Watch a mother and daughter share their experience with SUPPRELIN<sup>&reg;</sup> LA
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
